import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import { IsFullWidthRowParams } from 'ag-grid-community';
import {
  Button,
  Card,
  DatePicker,
  Form,
  FormProps,
  Input,
  Col,
  Row,
  Select,
  Checkbox,
  Flex,
  Empty,
  Tabs,
  Dropdown,
  MenuProps
} from 'antd';
import { IdcardOutlined, CaretDownFilled } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import ButtonGroup from 'antd/es/button/button-group';

import { TRANSACTION_PROCESSING_ACTION } from 'constants/cashier';

import TransactionTable from 'components/cashier/transaction-table';

import QUERY_KEYS from 'services/api/queryKeys';
import { toUtcTime } from 'utils';
import { RangeValueType } from 'services/api/type/common.type';
import { RENTAL_TYPE_OPTIONS } from 'constants/form';
import { getCashierGuestsColumns } from 'constants/ag-grid-table';
import 'styles/cashier.scss';

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

const fakeData = [
  {
    sts: 'IH',
    booking_id: 72361,
    confirm_id: 23493,
    name: 'LE DINH DAM',
    room_no: 205,
    balance: 200000,
    check_in: '11/09/2024',
    check_out: '12/09/2024'
  },
  {
    isFullWidth: true
  },
  {
    sts: 'IH',
    booking_id: 72342,
    confirm_id: 23471,
    name: 'ALEX VU TRAN',
    room_no: 403,
    balance: 5000000,
    check_in: '11/09/2024',
    check_out: '12/09/2024'
  }
];

function Cashier() {
  const [form] = Form.useForm();
  // const arrivalTimeForm = Form.useWatch('arrival_time', form);
  const arrivalFilterForm = Form.useWatch('arrival_filter', form);
  const departureFilterForm = Form.useWatch('departure_filter', form);

  const queryClient = useQueryClient();

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss')
  });

  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<any>(undefined);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW, page]
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { arrivalTimeForm, ...restFilterOptions } = truthyValues;

    if (arrivalTimeForm) {
      const startDate: Dayjs = arrivalTimeForm[0];
      const endDate: Dayjs = arrivalTimeForm[1];
      if (startDate) {
        _.set(restFilterOptions, 'arrival_from', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'arrival_to', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleArrivalTimeChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('arrival_time', [startDate, endDate]);
  };

  const handleDepartureTimeChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('departure_time', [startDate, endDate]);
  };

  const isFullWidthRow = (params: IsFullWidthRowParams) => {
    return params.rowNode.data?.isFullWidth;
  };
  const items: MenuProps['items'] = [
    {
      key: TRANSACTION_PROCESSING_ACTION.VIEW_DETAIL,
      label: 'View Transaction Detail'
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.EDIT_TRANSACTION,
      label: 'Edit Transaction'
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.VOID_TRANSACTION,
      label: 'Void Transaction'
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.MOVE_TRANSACTION,
      label: 'Move Transaction'
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.SPLIT_TRANSACTION,
      label: 'Split Transaction'
    }
  ];

  const cashierGuestsColumns = useMemo(() => getCashierGuestsColumns(), []);

  return (
    <div className="pms-cashier">
      <Row gutter={[8, 16]} className="pms-cashier__row">
        <Col xs={24} xl={11} xxl={8} className="flex flex-col h-full">
          <Card style={{ width: '100%' }}>
            <Form
              form={form}
              name="cashier-filter-form"
              layout={'inline'}
              initialValues={{
                arrivalTime: [initStartDate, initEndDate]
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={12}>
                  <Form.Item
                    label="Guest"
                    name="guest"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Group"
                    name="group"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="TA/Company"
                    name="travel_agency_name"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Rental Type"
                    name="rentalType"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Select options={RENTAL_TYPE_OPTIONS} />
                  </Form.Item>
                </Col>
              </Row>

              {/* Arrival */}
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={4}>
                  <Form.Item name="arrival_filter" valuePropName="checked">
                    <Checkbox>Arrival</Checkbox>
                  </Form.Item>
                </Col>

                <Col span={20} style={{ paddingLeft: 2 }}>
                  <Form.Item name="arrival_time" valuePropName="checked" className="w-full">
                    <DatePicker.RangePicker
                      className="w-full"
                      placeholder={['Từ ngày', 'Đến ngày']}
                      format="YYYY-MM-DD"
                      onChange={handleArrivalTimeChange}
                      disabled={!arrivalFilterForm}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Departure */}
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={4}>
                  <Form.Item name="departure_filter" valuePropName="checked">
                    <Checkbox>Departure</Checkbox>
                  </Form.Item>
                </Col>

                <Col span={20} style={{ paddingLeft: 2 }}>
                  <Form.Item name="departure_time" valuePropName="checked" className="w-full">
                    <DatePicker.RangePicker
                      className="w-full"
                      placeholder={['Từ ngày', 'Đến ngày']}
                      format="YYYY-MM-DD"
                      onChange={handleDepartureTimeChange}
                      disabled={!departureFilterForm}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Flex
                align="center"
                justify="space-between"
                wrap
                className="w-full checkbox-wrapper"
                style={{ marginBottom: 12 }}
              >
                <Form.Item name="non_zero_balance" valuePropName="checked">
                  <Checkbox>Non-zero Balance</Checkbox>
                </Form.Item>

                <Form.Item name="co_today" valuePropName="checked">
                  <Checkbox>C/O Today</Checkbox>
                </Form.Item>

                <Form.Item name="is_reserved" valuePropName="checked">
                  <Checkbox>Reserved</Checkbox>
                </Form.Item>

                <Form.Item name="is_in_house" valuePropName="checked">
                  <Checkbox>In House</Checkbox>
                </Form.Item>

                <Form.Item name="is_checked_out" valuePropName="checked">
                  <Checkbox>Checked Out</Checkbox>
                </Form.Item>
              </Flex>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit ml-auto">
                Tìm kiếm
              </Button>
            </Form>
          </Card>

          <Card
            style={{ width: '100%', marginTop: 12, flex: 1 }}
            className="flex flex-col pms-cashier-guests"
          >
            <p className="title">LIST GUESTS</p>
            <div className="pms-cashier-guests__table">
              <div className="pms-cashier-guests__table ag-theme-quartz">
                <AgGridReact
                  rowData={fakeData}
                  columnDefs={cashierGuestsColumns}
                  // loadingOverlayComponent={isFetchingRoomTypes}
                  // loadingCellRenderer={isFetchingRoomTypes}
                  onRowClicked={event => {
                    if (event.data?.isFullWidth) {
                      return;
                    }
                    setSelectedRow(event.data?.booking_id);
                  }}
                  stopEditingWhenCellsLoseFocus
                  getRowClass={params => {
                    return params.data.booking_id === selectedRow ? 'highlight-row' : '';
                  }}
                  isFullWidthRow={isFullWidthRow}
                  fullWidthCellRenderer={() => (
                    <div
                      className="flex items-center h-full fullwidth-row"
                      style={{ paddingLeft: 16 }}
                    >
                      <p className="m-0">Party: 3686</p>
                    </div>
                  )}
                />
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} xl={13} xxl={16} className="flex flex-col">
          <Card className="pms-cashier__reservation" style={{ maxWidth: '700px' }}>
            <p className="title">Reservation {selectedRow ? '72342 - EXPEDIA (10710)' : ''}</p>
            {selectedRow ? (
              <>
                <div className="flex items-center justify-between reservation-row">
                  <p className="time">Arr/Dept: 11/09/24 16:10 - 13/09/24 --:--</p>
                  <p className="rate">
                    Rate: <span className="rate--success">3,000,000 VND</span>
                  </p>
                </div>
                <div className="flex items-center justify-between reservation-row">
                  <p className="rate">
                    Balance: <span className="rate--error">2,250,150 VND</span>
                  </p>
                  <div className="flex items-center">
                    <IdcardOutlined />
                    <span style={{ marginLeft: 4 }}>
                      VIP: <span className="vip-balance"> 0 (0 VND)</span>
                    </span>
                  </div>
                </div>

                <div className="reservation-note"></div>

                <div className="text-right" style={{ marginTop: 16 }}>
                  <ButtonGroup style={{ columnGap: 8 }}>
                    <Button className="ant-btn-secondary">Checkout</Button>
                    <Button className="ant-btn-secondary">Edit Reservation</Button>
                  </ButtonGroup>
                </div>
              </>
            ) : (
              <Empty />
            )}
          </Card>

          <Card className="pms-cashier__transaction">
            <p className="title">Transaction List</p>
            {selectedRow ? (
              <>
                <Tabs
                  items={[
                    {
                      key: 'a',
                      label: 'A',
                      children: <TransactionTable />
                    },
                    {
                      key: 'f',
                      label: 'F',
                      children: <TransactionTable />
                    }
                  ]}
                ></Tabs>
              </>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>

      <div className="text-right" style={{ marginTop: 12 }}>
        <Button.Group className="flex-wrap">
          <Button className="ant-btn-secondary">+ Trans</Button>
          <Button className="ant-btn-secondary">+ Room Charge</Button>
          <Button className="ant-btn-secondary">+ HK Service</Button>
          <Dropdown menu={{ items }} placement="top" trigger={['click']}>
            <Button className="ant-btn-secondary">
              Trans Processing <CaretDownFilled />
            </Button>
          </Dropdown>
          <Button className="ant-btn-secondary">Payment</Button>
          <Button className="ant-btn-secondary">Refund</Button>
          <Button className="ant-btn-secondary">Print Deposit</Button>
          <Button className="ant-btn-secondary">Print Paid Out</Button>
          <Button className="ant-btn-secondary">Print Invoice</Button>
        </Button.Group>
      </div>
    </div>
  );
}

export default Cashier;
