import _ from 'lodash';
import { getToken } from 'services/storages/userStorage';

const PAGES = {
  auth: '/auth',
  login: '/login',
  register: '/register',
  activationEmail: '/activation',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  branches: '/branches',

  home: '/',
  homeMobile: '/mobile',
  bookingRoom: '/',
  roomMap: '/room-map',
  bookingList: '/booking-list',
  cashier: '/cashier',
  group: '/new-group',
  listGroup: '/list-group',
  detailGroup: '/detail-group/:groupId',
  roomLock: '/room-lock',

  room: '/room',
  cleaningCalendar: '/cleaning-calendar',
  roomStatus: '/room-status',
  cleaningDetail: '/cleaning-detail',

  receptionist: '/receptionist',
  receptionistGuestWillCheckIn: '/receptionist/will-check-in',
  receptionistGuestCheckIn: '/receptionist/check-in',
  receptionistGuestAllot: '/receptionist/allot',
  receptionistGuestWillCheckOut: '/receptionist/will-checkout',
  receptionistGuestCheckout: '/receptionist/checkout',
  receptionistUnavailable: '/receptionist/unavailable',

  audit: '/audit',
  report: '/report',
  setting: '/setting',

  stayingGuests: '/staying-guests',
  nationalityStatistic: '/nationality-statistic',
  roomAvailability: '/room-availability',
  transactions: '/transactions',
  breakfasts: '/breakfasts',
  houseStatus: '/house-status',
  shiftHandoverList: '/shift-handover-list',
  focsReport: '/focs-report',
  consolidateRevenueReport: '/consolidate-revenue-report',

  customerProfile: '/customer-profile/:customerId',
  shiftHandover: '/shift-handover',
  scanCustomerInfo: '/customers-info-ocr',
  cashBookDetail: '/cash-book-detail',
  nightAudit: '/night-audit',
  reservationForecast: '/reservation-forecast',
  warehouseManagement: '/warehouse-management',
  travelAgency: '/travel-agency',
  customersManagement: '/customers-management',

  hk: '/mobile/hk',
  subHK: '/mobile/sub-hk',

  chat: '/chat'
};

export function getFullUserProfilePath(userId: number) {
  return `${PAGES.customerProfile.replace(':customerId', String(userId))}`;
}

export function getDetailGroupURL(groupId: number) {
  return `${PAGES.detailGroup.replace(':groupId', String(groupId))}`;
}

export function getRedirectUrl(redirect: string) {
  const token = getToken();
  return `${String(process.env.REACT_APP_WEB_URL)}/#${
    PAGES.auth
  }?token=${token}&redirect=${encodeURIComponent(redirect)}`;
}

export const PATTERN_URL = {
  HOME: '/',
  HK: '/mobile/hk/*',
  SUB_HK: '/mobile/sub-hk/*'
};

export const MENU_HEADER_VALUE = {
  BOOKING_ROOM: 1,
  RECEPTIONIST: 2,
  CASHIER: 3,
  GROUP: 4,
  ROOM: 5,
  REPORT: 6,
  CASHFLOW: 7,
  NIGHT_AUDIT: 8,
  MANAGE: 9,
  WAREHOUSE_MANAGEMENT: 10,
  TRAVEL_AGENCY: 11,
  CUSTOMERS_MANAGEMENT: 12,
  CHAT: 13
};

export const MENU_HEADER = [
  {
    id: MENU_HEADER_VALUE.BOOKING_ROOM,
    label: 'Đặt phòng',
    url: PAGES.bookingRoom
  },
  {
    id: MENU_HEADER_VALUE.RECEPTIONIST,
    label: 'Lễ tân',
    url: PAGES.receptionistGuestWillCheckIn
  },
  {
    id: MENU_HEADER_VALUE.GROUP,
    label: 'Đoàn',
    url: PAGES.group
  },
  {
    id: MENU_HEADER_VALUE.ROOM,
    label: 'Buồng phòng',
    url: PAGES.cleaningCalendar
  },
  {
    id: MENU_HEADER_VALUE.MANAGE,
    label: 'Quản lý',
    key: 'SubMenu',
    children: [
      {
        id: MENU_HEADER_VALUE.REPORT,
        key: MENU_HEADER_VALUE.REPORT,
        label: 'Báo cáo',
        url: PAGES.stayingGuests
      },
      {
        id: MENU_HEADER_VALUE.NIGHT_AUDIT,
        key: MENU_HEADER_VALUE.NIGHT_AUDIT,
        label: 'Kiểm toán đêm',
        url: PAGES.nightAudit
      },
      {
        id: MENU_HEADER_VALUE.CUSTOMERS_MANAGEMENT,
        key: MENU_HEADER_VALUE.CUSTOMERS_MANAGEMENT,
        label: 'Khách hàng',
        url: PAGES.customersManagement
      },
      {
        id: MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT,
        key: MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT,
        label: 'Quản lý kho',
        url: PAGES.warehouseManagement
      },
      {
        id: MENU_HEADER_VALUE.TRAVEL_AGENCY,
        key: MENU_HEADER_VALUE.TRAVEL_AGENCY,
        label: 'TA',
        url: PAGES.travelAgency
      },
      {
        id: MENU_HEADER_VALUE.CASHFLOW,
        key: MENU_HEADER_VALUE.CASHFLOW,
        label: 'Thu chi',
        url: PAGES.cashBookDetail
      },
      {
        id: MENU_HEADER_VALUE.CHAT,
        key: MENU_HEADER_VALUE.CHAT,
        label: 'Chat',
        url: PAGES.chat
      }
    ]
  }
];

export const getMenuHeaderMobile = () => {
  const flattenMenu: any[] = _.flatMap(MENU_HEADER, item => (item.children ? item.children : item));
  return flattenMenu;
};

export const SUB_MENU_HEADER = {
  [MENU_HEADER_VALUE.BOOKING_ROOM]: [
    {
      label: 'Đặt phòng',
      url: PAGES.bookingRoom
    },
    {
      label: 'Sơ đồ phòng',
      url: PAGES.roomMap
    },
    {
      label: 'Danh sách đặt phòng',
      url: PAGES.bookingList
    },
    {
      label: 'Danh sách khóa phòng',
      url: PAGES.roomLock
    }
  ],
  [MENU_HEADER_VALUE.ROOM]: [
    {
      label: 'Lịch dọn',
      url: PAGES.cleaningCalendar
    },
    {
      label: 'Tình trạng phòng',
      url: PAGES.roomStatus
    }
    // {
    //   label: "Chi tiết dọn phòng",
    //   url: PAGES.cleaningDetail,
    // },
  ],
  [MENU_HEADER_VALUE.RECEPTIONIST]: [
    {
      label: 'Khách sẽ đến',
      url: PAGES.receptionistGuestWillCheckIn
    },
    {
      label: 'Khách đã đến',
      url: PAGES.receptionistGuestCheckIn
    },
    {
      label: 'Khách đang ở',
      url: PAGES.receptionistGuestAllot
    },
    {
      label: 'Khách sẽ đi',
      url: PAGES.receptionistGuestWillCheckOut
    },
    {
      label: 'Khách đã đi',
      url: PAGES.receptionistGuestCheckout
    },
    {
      label: 'Phòng chiếm dụng',
      url: PAGES.receptionistUnavailable
    }
  ],
  [MENU_HEADER_VALUE.GROUP]: [
    {
      label: 'Tạo đoàn',
      url: PAGES.group
    },
    {
      label: 'Danh sách Đoàn',
      url: PAGES.listGroup
    }
  ],
  [MENU_HEADER_VALUE.REPORT]: [
    {
      label: 'Khai báo CA/Hải quan',
      url: PAGES.stayingGuests
    },
    {
      label: 'Trạng thái phòng',
      url: PAGES.roomAvailability
    },
    {
      label: 'Danh sách giao dịch',
      url: PAGES.transactions
    },
    {
      label: 'Dự báo đặt phòng',
      url: PAGES.reservationForecast
    },
    {
      label: 'Danh sách ăn sáng',
      url: PAGES.breakfasts
    },
    {
      label: 'Tình trạng khách sạn',
      url: PAGES.houseStatus
    },
    {
      label: 'Phân tích Quốc tịch',
      url: PAGES.nationalityStatistic
    },
    {
      label: 'Báo cáo bàn giao ca',
      url: PAGES.shiftHandoverList
    },
    {
      label: 'Báo cáo FOC',
      url: PAGES.focsReport
    },
    {
      label: 'Báo cáo DT tổng hợp',
      url: PAGES.consolidateRevenueReport
    }
  ],
  [MENU_HEADER_VALUE.CASHFLOW]: [
    {
      label: 'Sổ chi tiết tiền mặt',
      url: PAGES.cashBookDetail
    }
  ],
  [MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT]: [
    {
      label: 'Tồn kho',
      url: PAGES.warehouseManagement
    }
  ],
  [MENU_HEADER_VALUE.CASHIER]: [
    {
      label: 'Giao dịch',
      url: PAGES.cashier
    }
  ]
};

export default PAGES;
