import React, { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { RowSelectedEvent } from 'ag-grid-community';

import { getCashierTransactionCols } from 'constants/ag-grid-table';

const fakeDataTransaction = [
  {
    trans_date: '12/09/2024',
    trans_code: 324,
    description: 'Extra person',
    amount: 130000,
    ref: 106,
    origin_rm: 406,
    notice: 'DEMO',
    clerk_id: 'KIN',
    posted_time: '12/09/2024'
  }
];

function TransactionTable() {
  const [selectedNodeIds, setSelectedNodeIds] = useState<(number | null)[]>([]);

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const nodeIds = event.api.getSelectedNodes().map(item => item.rowIndex);
    setSelectedNodeIds(nodeIds);
  };

  const cashierTransactionCols = useMemo(() => {
    return getCashierTransactionCols();
  }, []);

  console.log(selectedNodeIds);

  return (
    <div className="pmn-cashier__transaction-table ag-theme-quartz">
      <AgGridReact
        rowData={fakeDataTransaction}
        columnDefs={cashierTransactionCols}
        rowSelection="multiple"
        // loadingOverlayComponent={isFetchingRoomTypes}
        // loadingCellRenderer={isFetchingRoomTypes}
        // onCellValueChanged={handleChangeCellValue}
        onRowSelected={handleChangeSelectedRow}
        stopEditingWhenCellsLoseFocus
      />

      <div style={{ marginTop: 12 }}>
        <h5 className="m-0" style={{ fontWeight: 600 }}>
          Balance: <span className="text-error">-677,500</span>
        </h5>
      </div>
    </div>
  );
}

export default TransactionTable;
