import { create } from 'zustand';
import { SubGroup } from 'services/api/type/group.type';

interface PropsModal {
  title: string;
  okText?: string;
  cancelText?: string;
  onOk: (params?: any) => void;
}
export interface ModalType {
  isOpenAssignRoom: boolean;
  setIsOpenAssignRoom: (isOpen: boolean) => void;

  isOpenChangeRoomType: boolean;
  setIsOpenChangeRoomType: (isOpen: boolean) => void;

  isOpenCancelAssignRoom: boolean;
  setIsOpenCancelAssignRoom: (isOpen: boolean) => void;

  isOpenCancelRoom: boolean;
  setIsOpenCancelRoom: (isOpen: boolean) => void;

  isOpenConfirmModal: boolean;
  confirmLoading?: boolean;
  propsConfirmModal: PropsModal | undefined;
  setInfoConfirmModal: (isOpen: boolean, propsConfirmModal?: PropsModal | undefined) => void;
  setConfirmLoading: (loading: boolean) => void;

  isOpenCustomer: boolean;
  setIsOpenCustomer: (isOpen: boolean) => void;

  isOpenAddService: boolean;
  setIsOpenAddService: (isOpen: boolean) => void;

  isOpenChangeRoom: boolean;
  setIsOpenChangeRoom: (isOpen: boolean) => void;

  isOpenChangeDate: boolean;
  setIsOpenChangeDate: (isOpen: boolean) => void;

  isOpenChangePrice: boolean;
  setIsOpenChangePrice: (isOpen: boolean) => void;
  isOpenChangePriceGroup: boolean;
  setIsOpenChangePriceGroup: (isOpen: boolean) => void;

  isOpenViewTrackingLogs: boolean;
  setIsOpenViewTrackingLogs: (isOpen: boolean) => void;

  isOpenBookingDetail: boolean;
  bookingLineId?: number;
  setOpenBookingDetail: (isOpen: boolean, bookingLineId?: number) => void;
  setBookingLineId: (bookingLineId?: number) => void;

  isOpenViewPDF: boolean;
  setIsOpenViewPDF: (isOpen: boolean) => void;

  isOpenViewPDFGroup: boolean;
  setIsOpenViewPDFGroup: (isOpen: boolean) => void;

  isOpenConfirmationPDF: boolean;
  setIsOpenConfirmationPDF: (isOpen: boolean) => void;

  isOpenFixRoom: boolean;
  isCreateFixRoom: boolean;
  setIsOpenFixRoom: (isOpen: boolean, isCreateFixRoom?: boolean) => void;

  paymentId: number | undefined;
  isOpenConfirmDeletePayment: boolean;
  setIsOpenConfirmDeletePayment: (isOpen: boolean, paymentId?: number) => void;

  amount: number | undefined;
  state: string | undefined;
  isOpenViewChargeDebtPayment: boolean;
  setIsOpenViewChargeDebtPayment: (
    isOpen: boolean,
    paymentId?: number,
    amount?: number,
    state?: string
  ) => void;

  isOpenAddExpense: boolean;
  setIsOpenAddExpense: (isOpen: boolean) => void;

  isOpenResolveDocument: boolean;
  document: any;
  setIsOpenResolveDocument: (isOpen: boolean, document?: any) => void;

  isOpenSetBreakfast: boolean;
  setIsOpenSetBreakfast: (isOpen: boolean) => void;

  isOpenUndoCheckIn: boolean;
  setIsOpenUndoCheckIn: (isOpen: boolean) => void;

  isOpenUndoCheckOut: boolean;
  setIsOpenUndoCheckOut: (isOpen: boolean) => void;

  isOpenSignature: boolean;
  propsSignatureModal: PropsModal | undefined;
  setIsOpenSignature: (isOpen: boolean, propsModal?: PropsModal | undefined) => void;

  isOpenChangePassword: boolean;
  setIsOpenChangePassword: (isOpen: boolean) => void;

  isOpenPayment: boolean;
  setIsOpenPayment: (isOpen: boolean) => void;

  isOpenCreateTravelAgency: boolean;
  setIsOpenCreateTravelAgency: (isOpen: boolean) => void;

  isOpenUpdateAndDeleteTravelAgency: boolean;
  setIsOpenUpdateAndDeleteTravelAgency: (isOpen: boolean) => void;

  isOpenGroupRoomingList: boolean;
  groupRoomingData: any;
  setIsOpenGroupRoomingList: (isOpen: boolean, groupRoomingData?: any) => void;

  isOpenSubGroupDetail: boolean;
  subGroupDetail: SubGroup | undefined;
  setIsOpenSubGroupDetail: (isOpenSubGroupDetail: boolean) => void;
  setSubGroupDetail: (subGroupDetail: SubGroup | undefined) => void;

  isOpenChangeDateGroupBooking: boolean;
  setIsOpenChangeDateGroupBooking: (isOpen: boolean) => void;

  isOpenCheckRoomAvailability: boolean;
  setIsOpenCheckRoomAvailability: (isOpen: boolean) => void;

  isOpenFocDetails: boolean;
  setIsOpenFocDetails: (isOpen: boolean) => void;

  isPDFOpen: boolean;
  setIsPDFOpen: (isPDFOpen: boolean) => void;
}

const useModal = create<ModalType>(set => ({
  isOpenAssignRoom: false,
  setIsOpenAssignRoom: (isOpen: boolean) => set(() => ({ isOpenAssignRoom: isOpen })),

  isOpenChangeRoomType: false,
  setIsOpenChangeRoomType: (isOpen: boolean) => set(() => ({ isOpenChangeRoomType: isOpen })),

  isOpenCancelAssignRoom: false,
  setIsOpenCancelAssignRoom: (isOpen: boolean) => set(() => ({ isOpenCancelAssignRoom: isOpen })),

  isOpenCancelRoom: false,
  setIsOpenCancelRoom: (isOpen: boolean) => set(() => ({ isOpenCancelRoom: isOpen })),

  isOpenViewTrackingLogs: false,
  setIsOpenViewTrackingLogs: (isOpen: boolean) => set(() => ({ isOpenViewTrackingLogs: isOpen })),

  isOpenConfirmModal: false,
  propsConfirmModal: undefined,
  setInfoConfirmModal: (isOpen: boolean, propsConfirmModal: PropsModal | undefined) =>
    set(() => ({
      isOpenConfirmModal: isOpen,
      propsConfirmModal
    })),
  setConfirmLoading: (loading: boolean) =>
    set(() => ({
      confirmLoading: loading
    })),

  isOpenCustomer: false,
  setIsOpenCustomer: (isOpen: boolean) => set(() => ({ isOpenCustomer: isOpen })),

  isOpenAddService: false,
  setIsOpenAddService: (isOpen: boolean) => set(() => ({ isOpenAddService: isOpen })),

  isOpenChangeRoom: false,
  setIsOpenChangeRoom: (isOpen: boolean) => set(() => ({ isOpenChangeRoom: isOpen })),

  isOpenChangeDate: false,
  setIsOpenChangeDate: (isOpen: boolean) => set(() => ({ isOpenChangeDate: isOpen })),

  isOpenChangePrice: false,
  setIsOpenChangePrice: (isOpen: boolean) => set(() => ({ isOpenChangePrice: isOpen })),
  isOpenChangePriceGroup: false,
  setIsOpenChangePriceGroup: (isOpen: boolean) => set(() => ({ isOpenChangePriceGroup: isOpen })),

  isOpenBookingDetail: false,
  setOpenBookingDetail: (isOpen: boolean, bookingLineId?: number) =>
    set(() => ({
      isOpenBookingDetail: isOpen,
      bookingLineId
    })),
  setBookingLineId: (bookingLineId?: number) => set(() => ({ bookingLineId })),

  isOpenViewPDF: false,
  setIsOpenViewPDF: (isOpen: boolean) => set(() => ({ isOpenViewPDF: isOpen })),

  isOpenViewPDFGroup: false,
  setIsOpenViewPDFGroup: (isOpen: boolean) => set(() => ({ isOpenViewPDFGroup: isOpen })),

  isOpenConfirmationPDF: false,
  setIsOpenConfirmationPDF: (isOpen: boolean) => set(() => ({ isOpenConfirmationPDF: isOpen })),

  isOpenFixRoom: false,
  isCreateFixRoom: false,
  setIsOpenFixRoom: (isOpen: boolean, isCreateFixRoom?: boolean) =>
    set(() => ({ isOpenFixRoom: isOpen, isCreateFixRoom })),

  paymentId: undefined,
  isOpenConfirmDeletePayment: false,
  setIsOpenConfirmDeletePayment: (isOpen: boolean, paymentId?: number) =>
    set(() => ({ isOpenConfirmDeletePayment: isOpen, paymentId })),

  amount: undefined,
  state: undefined,
  isOpenViewChargeDebtPayment: false,
  setIsOpenViewChargeDebtPayment: (
    isOpen: boolean,
    paymentId?: number,
    amount?: number,
    state?: string
  ) => set(() => ({ isOpenViewChargeDebtPayment: isOpen, paymentId, amount, state })),

  isOpenAddExpense: false,
  setIsOpenAddExpense: (isOpen: boolean) => set(() => ({ isOpenAddExpense: isOpen })),

  isOpenResolveDocument: false,
  document: undefined,
  setIsOpenResolveDocument: (isOpen: boolean, document?: any) =>
    set(() => ({ isOpenResolveDocument: isOpen, document })),

  isOpenSetBreakfast: false,
  setIsOpenSetBreakfast: (isOpen: boolean) => set(() => ({ isOpenSetBreakfast: isOpen })),

  isOpenUndoCheckIn: false,
  setIsOpenUndoCheckIn: (isOpen: boolean) => set(() => ({ isOpenUndoCheckIn: isOpen })),

  isOpenUndoCheckOut: false,
  setIsOpenUndoCheckOut: (isOpen: boolean) => set(() => ({ isOpenUndoCheckOut: isOpen })),

  isOpenSignature: false,
  propsSignatureModal: undefined,
  setIsOpenSignature: (isOpen: boolean, propsSignatureModal: PropsModal | undefined) =>
    set(() => ({
      isOpenSignature: isOpen,
      propsSignatureModal
    })),

  isOpenChangePassword: false,
  setIsOpenChangePassword: (isOpenChangePassword: boolean) => set(() => ({ isOpenChangePassword })),

  isOpenPayment: false,
  setIsOpenPayment: (isOpenPayment: boolean) => set(() => ({ isOpenPayment })),

  isOpenCreateTravelAgency: false,
  setIsOpenCreateTravelAgency: (isOpenCreateTravelAgency: boolean) =>
    set(() => ({ isOpenCreateTravelAgency })),

  isOpenUpdateAndDeleteTravelAgency: false,
  setIsOpenUpdateAndDeleteTravelAgency: (isOpenUpdateAndDeleteTravelAgency: boolean) =>
    set(() => ({ isOpenUpdateAndDeleteTravelAgency })),

  isOpenGroupRoomingList: false,
  groupRoomingData: undefined,
  setIsOpenGroupRoomingList: (isOpenGroupRoomingList: boolean, groupRoomingData?: any) =>
    set(() => ({ isOpenGroupRoomingList, groupRoomingData })),

  isOpenSubGroupDetail: false,
  subGroupDetail: undefined,
  setIsOpenSubGroupDetail: (isOpenSubGroupDetail: boolean) => set(() => ({ isOpenSubGroupDetail })),
  setSubGroupDetail: (subGroupDetail: SubGroup | undefined) => set(() => ({ subGroupDetail })),

  isOpenChangeDateGroupBooking: false,
  setIsOpenChangeDateGroupBooking: (isOpenChangeDateGroupBooking: boolean) =>
    set(() => ({ isOpenChangeDateGroupBooking })),

  isOpenCheckRoomAvailability: false,
  setIsOpenCheckRoomAvailability: isOpenCheckRoomAvailability =>
    set(() => ({ isOpenCheckRoomAvailability })),

  isOpenFocDetails: false,
  setIsOpenFocDetails: isOpenFocDetails => set(() => ({ isOpenFocDetails })),

  isPDFOpen: false,
  setIsPDFOpen: isPDFOpen => set(() => ({ isPDFOpen }))
}));

export default useModal;
