import { Payment } from './payment.type';
import { PersonalInfoType, RoomDetailStatus, RoomLock } from './room.type';
import { Labels } from './labels.type';

export enum BookingStatus {
  INITIAL = 'initial',
  CONFIRM = 'initial', // same as initial for now
  CHECK_IN = 'allot',
  CANCEL = 'cancel',
  CHECK_OUT = 'checkout'
}
export interface UpdateInvoiceDetailsType {
  partner_name: string;
  company_name: string;
  company_address: string;
  tax_code: string;
}

export interface Pricelist {
  id: number;
  name: string;
}
export interface Log {
  id: string;
  create_date: string;
  user_id: string;
  user_name: string;
  user_email: string;
  booking_line_id: string;
  type: string;
  log: string;
}
export interface BookingPrice {
  id: number;
  start_date: string;
  end_date: string;
  price: number;
}

export interface BookingLine {
  booking_line_id: number;
  booking_line_sequence_id: string;
  room_id: number;
  room_name: string;
  room_type_id: number;
  room_type_name: string;
  original_room_type_name: string;
  room_type_short_name: string;
  price: number;
  adult: number;
  child: number;
  booking_days: number;
  booking_id: number;
  subtotal_price: number;
  total_price: number;
  paid_amount: number;
  booking_sequence_id: string;
  partner_id: number;
  partner_name: string;
  sale_order_name: string;
  check_in: string;
  check_out: string;
  note: string;
  status: BookingStatus;
  pricelist: Pricelist;
  booking_prices: BookingPrice[];
  customers: PersonalInfoType[];
  expandableRows?: BookingLine[];
  cancel_reason: string;
  cancel_price: number;
  services?: GetServiceType[];
  source_id?: number;
  source_name?: string;
  medium_id?: number;
  medium_name?: string;
  cms_booking_id?: string;
  cms_ota_id?: string;
  cms_booking_source?: string;
  tax: number;
  breakfast_dates: BreakfastType[];
  payments: Payment[];
  attachments: AttachmentType[];
  partner_identification: string | boolean;
  actual_check_in?: string;
  actual_check_out?: string;
  invoice_details: UpdateInvoiceDetailsType;
  labels: Labels[];
  group?: {
    name: string;
    booking_lines: GroupBookingLineItem[];
  };
  hotel_travel_agency_name?: string;
  hotel_travel_agency_id?: number;
  create_date: string;
}

export interface GroupBookingLineItem {
  id: number;
  status: string;
  room_id: number;
  room_name: string;
  room_type_id: number;
  room_type_name: string;
}

export interface CreateBookingResponseType {
  booking_id: number;
  booking_lines: { booking_line_id: number }[];
}

export interface GetServiceType {
  id: number;
  name: string;
  price: number;
  total_price: number;
  subtotal_price: number;
  is_deleted: boolean;
  note: string;
  username: string;
  created_date: string;
  qty: number;
  tax: number;
}

export interface PaginationType {
  total: number;
  limit: number;
  page: number;
}

export interface ParamsCancelBooking {
  payment_method: string;
  fee: number;
  note: string;
  is_send_email?: boolean;
}

export interface BookingServiceType {
  id: number;
  name: string;
  price: number;
  qty?: number;
  category: string;
  sub_category: string;
  allow_foc: boolean;
  allow_custom_price: boolean;
}

export interface BreakfastType {
  id: number;
  active: boolean;
  date: string;
}

export interface ParamsAddServices {
  service_id: number;
  note: string;
  price: number;
}

export interface ParamsChangePrices {
  prices: {
    id: number;
    price: number;
  }[];
  pricelist_id: number;
}

export interface ParamsExtraInfo {
  medium_id: number;
  source_id: number;
  note: string;
  label_name?: string;
  cms_ota_id?: string;
  hotel_travel_agency_id?: number;
}

export interface AttachmentType {
  id: number;
  name: string;
  url: string;
  created_date: string;
}

export type RoomDetailStatusPicked = Pick<
  RoomDetailStatus,
  'room_id' | 'room_name' | 'is_clean' | 'room_type_name'
>;

export type NewRoomLock = RoomLock & {
  room_id: number;
  lock_id: number;
  attributes: {
    room_no: string;
  };
};
export interface ResponseTimeline {
  bookings: BookingLine[];
  locks: NewRoomLock[];
  rooms: RoomDetailStatusPicked[];
}

export enum BATCH_ACTION_TYPE {
  CHECK_IN = 'check_in',
  CHECK_OUT = 'check_out',
  AUTO_ASSIGN = 'auto_assign',
  CANCEL = 'cancel',
  CHANGE_DATE = 'change_date',
  UNASSIGN = 'unassign'
}
export interface ParamsBatchAction {
  action: BATCH_ACTION_TYPE;
  booking_line_ids: number[];
  reason?: string;
  check_in?: string;
  check_out?: string;
}

export interface BookingsBatchActions {
  action: BATCH_ACTION_TYPE;
  booking_ids: number[];
}
