enum QUERY_KEYS {
  GET_MOCK_DATA = 'GET_MOCK_DATA',
  AUTOCOMPLETE_SEARCH = 'AUTOCOMPLETE_SEARCH',
  GET_BOOKING_LIST = 'GET_BOOKING_LIST',
  GET_BOOKING_LIST_ALL = 'GET_BOOKING_LIST_ALL',
  GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL',
  GET_ROOM_MAP = 'GET_ROOM_MAP',
  KEY_ROOM = 'KEY_ROOM',
  GET_ROOM_STATUS = 'GET_ROOM_STATUS',
  GET_AVAIL_ROOM_TYPE = 'GET_AVAIL_ROOM_TYPE',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_PAYMENTS = 'GET_PAYMENTS',
  GET_COUNTRIES = 'GET_COUNTRIES',
  GET_AREAS = 'GET_AREAS',
  GET_ME = 'GET_ME',
  GET_SERVICES = 'GET_SERVICES',
  GET_CLEANING_CALENDAR = 'GET_CLEANING_CALENDAR',
  GET_COMPANIES = 'GET_COMPANIES',
  GET_SOURCES = 'GET_SOURCES',
  GET_STAYING_GUESTS = 'GET_STAYING_GUESTS',
  GET_CASH_FLOW = 'GET_CASH_FLOW',
  GET_CASH_FLOW_ALL = 'GET_CASH_FLOW_ALL',
  GET_CURRENT_NIGHT_AUDIT = 'GET_CURRENT_NIGHT_AUDIT',
  GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID',
  GET_ROOM_AVAILABILITY = 'GET_ROOM_AVAILABILITY',
  GET_ROOM_SUMMARY = 'GET_ROOM_SUMMARY',
  GET_ROOM_LOCK = 'GET_ROOM_LOCK',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_ALL_BOOKING_LINES_BY_BOOKING_ID = 'GET_ALL_BOOKING_LINES_BY_BOOKING_ID',
  GET_RESERVATION_FORECAST = 'GET_RESERVATION_FORECAST',
  GET_TRACKING_LOGS = 'GET_TRACKING_LOGS',
  GET_BREAKFASTS = 'GET_BREAKFASTS',
  GET_HOUSE_STATUS = 'GET_HOUSE_STATUS',
  GET_SUB_HK_ROOM = 'GET_SUB_HK_ROOM',
  GET_HK_ROOM = 'GET_HK_ROOM',
  GET_SUB_HK_STAFF = 'GET_SUB_HK_STAFF',
  GET_LIST_MINIBAR = 'GET_LIST_MINIBAR',
  GET_ALL_BRANCHES = 'GET_ALL_BRANCHES',
  GET_CURRENT_BRANCH = 'GET_CURRENT_BRANCH',
  GET_ALL_NATIONALITY = 'GET_ALL_NATIONALITY',
  GET_BOOKING_TIMELINE = 'GET_BOOKING_TIMELINE',
  GET_ALL_LABELS = 'GET_ALL_LABELS',
  GET_CURRENT_SHIFT_HANDOVER = 'GET_CURRENT_SHIFT_HANDOVER',
  GET_ALL_SHIFT_HANDOVER = 'GET_ALL_SHIFT_HANDOVER',
  GET_LATEST_OCR_CUSTOMER_INFO = 'GET_LATEST_OCR_CUSTOMER_INFO',
  GET_ALL_WAREHOUSE_PRODUCTS = 'GET_ALL_WAREHOUSE_PRODUCTS',
  GET_TRAVEL_AGENCIES = 'GET_TRAVEL_AGENCIES',

  GET_GROUP_BOOKINGS = 'GET_GROUP_BOOKINGS',
  GET_GROUP_BOOKING_DETAIL = 'GET_GROUP_BOOKING_DETAIL',

  GET_FOC_LIST = 'GET_FOC_LIST',
  GET_FOC_DETAILS = 'GET_FOC_DETAILS',

  GET_CONSOLIDATE_REVENUE = 'GET_CONSOLIDATE_REVENUE'
}

export default QUERY_KEYS;
